import { Controller } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import map from 'lodash/map';

import { FormatOptionLabelType } from '../../MultiSelect/types';
import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../MultiSelectField';
import { GeneralLedgerMultiSelectValueType } from './components/GeneralLedgersMultiSelectFieldControl/GeneralLedgersMultiSelectFieldControl.types';
import { CompanyNanoID } from '../../../main/companies/companiesTypes';
import { UserID } from '../../../main/users/usersTypes';
import { GeneralLedgerGeneralLedgerTypes } from '../../../main/generalLedgers/generalLedgersTypes';
import { GeneralLedgerIdsSelectDataType } from './GeneralLedgersSelectField.types';

import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../main/generalLedgers/queries/fetchGeneralLedgers.query';

import { useFinPaginatedGeneralLedgers } from '../../../main/generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { useTranslate } from '../../../common/hooks/useTranslate';
import { usePreviousValue } from '../../../common/hooks/usePreviousValue';

import { MultiSelectProps } from '../../MultiSelect';
import { GeneralLedgersMultiSelectFieldControl } from './components/GeneralLedgersMultiSelectFieldControl';

import { GeneralLedgerCache } from '../../../main/generalLedgers/GeneralLedgerCache';
import { MoneyHelper } from '../../MoneyHelper';

import { invoicesKeys } from '../../../locales/keys';

interface GeneralLedgersSelectFieldWithCompanyProps {
  companyNanoId: CompanyNanoID;
  userId?: never;
}

interface GeneralLedgersSelectFieldWithUserProps {
  companyNanoId?: never;
  userId: UserID;
}

type GeneralLedgersSelectFieldProps = {
  withSecondNewAvPrefix?: boolean;
} & (
  | GeneralLedgersSelectFieldWithCompanyProps
  | GeneralLedgersSelectFieldWithUserProps
);

function GeneralLedgersSelectField<FormDataType>({
  control,
  withSecondNewAvPrefix,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error,
  defaultValue,
  menuPlacement,
  companyNanoId: defaultCompanyNanoId,
  userId,
  menuPosition
}: GeneralLedgersSelectFieldProps &
  MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType> &
  Pick<MultiSelectProps, 'menuPlacement'>) {
  const { t } = useTranslate();

  const initialFilters = useMemo(() => {
    const companyNanoId = defaultCompanyNanoId
      ? { eq: defaultCompanyNanoId }
      : undefined;
    const companyCurrentForUsers = userId ? { eq: userId } : undefined;

    return {
      companyNanoId,
      companyCurrentForUsers
    };
  }, [defaultCompanyNanoId, userId]);

  const {
    generalLedgers,
    generalLedgersError,
    generalLedgersLoading,
    filterGeneralLedgers
  } = useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
    cacheKey: defaultCompanyNanoId
      ? GeneralLedgerCache.companyGeneralLedgersCacheKey(defaultCompanyNanoId)
      : GeneralLedgerCache.userGeneralLedgersCacheKey(userId),
    query: FETCH_GENERAL_LEDGERS_QUERY,
    initialFilters,
    options: {
      withoutPrefetch: true
    }
  });

  const prevFilters = usePreviousValue(initialFilters);

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterGeneralLedgers(initialFilters);
    }
  }, [prevFilters, filterGeneralLedgers, initialFilters]);

  const formatOptionLabel = useCallback<FormatOptionLabelType>(
    (option: GeneralLedgerIdsSelectDataType, { context }) => {
      if (context === 'menu') {
        return (
          <div className="text-gray-800 dark:text-gray-200 cursor-default focus:text-gray-900 dark:focus:text-gray-100 focus:bg-gray-100 dark:focus:bg-gray-900 select-none relative focus:ring-base focus:ring-offset-0 flex justify-between gap-2 items-center">
            <span className="block truncate">{option.label}</span>
            <span className="italic text-2xs text-gray-500">
              <MoneyHelper value={option.cashAccount?.balance} />
            </span>
          </div>
        );
      }

      return option.label;
    },
    []
  );

  const options = useMemo(() => {
    return map(generalLedgers, (item) => ({
      label:
        item.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
          ? t(invoicesKeys.generalLedger)
          : item.name,
      value: item.id,
      cashAccount: item.cashAccount
    }));
  }, [generalLedgers, t]);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <GeneralLedgersMultiSelectFieldControl
          data={options}
          isLoading={generalLedgersLoading}
          defaultValue={defaultValue}
          disabled={disabled}
          formatOptionLabel={formatOptionLabel}
          error={fieldError?.message || generalLedgersError || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          labelClassName={labelClassName}
          multi={false}
          onChange={onChange}
          value={value as GeneralLedgerMultiSelectValueType}
          name={name}
          classNamePrefix={
            withSecondNewAvPrefix
              ? fieldError
                ? 'second_new_av_error'
                : 'second_new_av'
              : 'av'
          }
          inputWrapperClassName="w-full"
          isSearchable
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
        />
      )}
    />
  );
}

export default GeneralLedgersSelectField;
